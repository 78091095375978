<template>
    <div>
        <Modal :title="title" v-model="isShowModal">
            <template v-slot:modal-content>
                <div class="alert-word">
                    {{ message }}
                </div>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click="turnOffModal"
                />
                <Button
                    buttonWord="確定"
                    buttonStyle="blue"
                    @click="toggleCommissionVisible()"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import { setCommissionVisibleAPI } from '@/assets/javascripts/api'

export default {
    name: 'AdminSetVisibleAlert',
    emits: ['update:modelValue', 'update:success', 'update:commissionStatus'],
    inject: ['mTypeTitle'],
    components: {
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean
        },
        isVisible: {
            type: Boolean
        },
        commissionStatus: {
            type: String
        },
        success: {
            type: Boolean
        },
        mType: {
            type: String
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        },
        toggleCommissionVisible: async function () {
            this.$setGaEvent(
                this.isVisible ? 'setVisibleTrue' : 'setVisibleFalse',
                'click-Button'
            )
            this.$showLoading()
            try {
                const res = await setCommissionVisibleAPI(
                    this.isVisible,
                    this.mType
                )

                if (res.data?.msg === 'still processing') {
                    this.$showErrorMessage(
                        '正在使用「開放業務員查詢」作業中',
                        `請待後台使用完成後，稍後再進行${this.mTypeTitle}的「開放業務員查詢」作業。\n如有疑問，請聯繫資訊客服，謝謝配合！`
                    )
                    return
                }
                this.commissionStatusValue = this.isVisible
                    ? 'visible'
                    : 'closed'
                this.$emit('update:success', true)
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.$showErrorMessage(this.errorMessage)
            } finally {
                this.$hideLoading()
                this.turnOffModal()
                window.scrollTo(0, 0)
            }
        }
    },
    computed: {
        title: function () {
            return this.isVisible
                ? '確定要開放業務員查詢嗎？'
                : '確定要關閉業務員查詢嗎？'
        },
        message: function () {
            return this.isVisible
                ? '請注意，開放查詢後，業務夥伴將可於「佣金查詢」查到本月份的佣金資料，請務必確認佣金資料的正確性。'
                : '請注意，關閉查詢後，業務夥伴將無法於「佣金查詢」查到本月份的佣金資料，請務必確認是否要關閉查詢。'
        },
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        commissionStatusValue: {
            get() {
                return this.commissionStatus
            },
            set(val) {
                this.$emit('update:commissionStatus', val)
            }
        }
    },
    data() {
        return {
            errorMessage: '切換業務員查詢狀態時出現錯誤..建議您重新整理頁面'
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-word {
    font-size: 14px;
    color: $secondary-grey;
}
</style>
